import styled from "@emotion/styled"
import { Box, Row, Col } from "@x-apps-projetos/design-system"

export const FormWrapper = styled(Box)`
  z-index: 2;
`

export const FormRow = styled(Row)`
  & > div {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const FormColumn = styled(Col)`
  padding-top: 0px;
  padding-bottom: 0px;
`