import React from "react"
import { graphql } from "gatsby"
import SEO from "@components/Seo/Seo.component"
import "@x-apps-projetos/design-system/dist/esm/index.css"
import { LandingPage } from "@components/_v2/landing-page"
import { ThemeProvider, DEFAULT_THEME } from "@x-apps-projetos/design-system"
import { useStoreCampaignVariablesInCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"


function LandingPageTemplateV2({ data }) {
  const {
    lp: { frontmatter: lp },
    markdownRemark: { frontmatter },
  } = data
  useStoreCampaignVariablesInCookies()

  return (
    <ThemeProvider theme={DEFAULT_THEME}>
      <LandingPage lp={lp} {...frontmatter}>
        <SEO
          keywords={lp.keywords}
          title={lp.plainTitle}
          description={lp.plainSubtitle}
        />
      </LandingPage>
    </ThemeProvider>
  )
}

export const query = graphql`
  query LandingPageV2($slug: String!, $language: String!) {
    lp: markdownRemark(
      fileAbsolutePath: { glob: "**/content/landing-pages-v2/*.md" }
      fields: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title: markdownField(from: "title")
        subtitle: markdownField(from: "subtitle")
        plainTitle: plainText(from: "title")
        plainSubtitle: plainText(from: "subtitle")
        slug
        keywords        
      }
    },
    markdownRemark(
      fileAbsolutePath: { glob: "**/content/landing-pages-v2-shared/*.md" }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        language
        mautic_form_id
        mautic_form_ebook_id
        section_form {
          form_title
          form_field_name_label
          form_field_name_placeholder
          form_field_surname_label
          form_field_surname_placeholder
          form_field_email_label
          form_field_email_placeholder
          form_field_phone_label
          form_field_phone_placeholder
          form_submit_button_label
          form_ebook_title
          form_ebook_description
          form_ebook_button_label
        }
        section_header {
          links {
            label
            section
            evidence
          }
        }
        section_hero {
          companies {
            name
            logo {
              publicURL
            }
          }
        }
        section_methods {
          title
          methods {
            title
            description
            icon {
              publicURL
            }
          }
        }
        section_cases {
          title
          description
          cases {
            image {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            name
          }
        }
        section_brands {
          title
          brands {
            name
            logo {
              publicURL
            }
          }
        }
        section_faq {
          button_contact_label
          title
          doubts {
            answer
            question
          }
        }
        section_gartner {
          button_label
          logo {
            publicURL
          }
          title
        }
        section_footer {
          description
          doubts {
            description
            download_button_label
            title
          }
        }
      }
    }
  }
`

export default LandingPageTemplateV2
