import React from "react"
import Image from "gatsby-image"
import { Container, Space, Row, Col, Stack, Text } from "@x-apps-projetos/design-system"
import { Wrapper } from "./style"
import Slider from "react-slick"
import { SliderPagination } from "@components/_v2/_shared/slider-pagination"
import { PageIndicator } from "@components/_v2/_shared/slider-pagination/style"

export const Cases = React.memo(({ content }) => {

  const sliderRef = React.useRef()

  return (
    <Wrapper id="section-cases">
      <div style={{
        maxWidth: "100%"
      }}>
        <Container maxWidth={1184}>
          <Space xs={{ "py": "32", "expand": true }}>
            <Row>
              <Col xs={12} md={7}>
                <Space xs={{ "px": "32", "py": "32", "expand": true }}>
                  <Stack
                    xs={{ "direction": "column", "spacing": "24", "align": "center" }}
                    md={{ "align": "flex-start" }}
                  >
                    <Text
                      as="h2"
                      value={content.title}
                      xs={{ "color": "white", "variant": "h3", "align": "center" }}
                      md={{ "align": "start" }}
                    />
                    <Text
                      as="h6"
                      value={content.description}
                      xs={{ "color": "white", "variant": "h6", "align": "center" }}
                      md={{ "align": "start" }}
                    />
                  </Stack>
                </Space>
              </Col>
            </Row>
          </Space>
        </Container>
        <Slider
          ref={sliderRef}
          key={"slider-cases"}
          initialSlide={0}
          infinite={true}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={1}
          variableWidth={true}
          autoplay={true}
          autoplaySpeed={4000}
          arrows={false}
          pauseOnHover={false}
          cssEase={"linear"}
          draggable={false}
          dots={true}
          customPaging={() => <PageIndicator />}
          appendDots={(dots) => (
            <>
              <Container
                maxWidth={1184}
                xs={{ "paddingLeft": "zero", "paddingRight": "zero" }}
                md={{ "paddingLeft": "24", "paddingRight": "24" }}
              >
                <Row>
                  <Col xs={12} md={7}>
                    <SliderPagination
                      dots={dots}
                      sliderRef={sliderRef}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )}
        >
          {content.cases.map((caseObject, index) => (
            <div style={{ width: 372, height: 516 }}>
              <Image
                key={`case-${index}`}
                alt={caseObject.name}
                fixed={caseObject.image.childImageSharp.fixed}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Wrapper>
  )
})