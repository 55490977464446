import React from "react"
import { Container, Space, Row, Col, Stack, Text, Box, Center } from "@x-apps-projetos/design-system"
import { Wrapper } from "./style"

import { FormComponent } from "../form"
import animatedLottie from "./assets/animation.json"

const MaybeLottie = () => {
  if( typeof document !== 'undefined' ){
      const { Lottie } = require("react-lottie")
      return !Lottie ? null : (
        <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animatedLottie,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="100%"
          />
      )
  }
  return null;
}

export const Hero = React.memo(({ lp, content, form }) => {
  
  // if( typeof document !== 'undefined'){
  //   const { Lottie } = require("react-lottie")
  // }

  return (
    <Wrapper id="section-hero">
      <Container maxWidth={1184}>
        <Space
          xs={{ "pt": "zero", "expand": true }}
          md={{ "pt": "64", "px": "48" }}
        >
          <Row xs={{ "direction": "column-reverse" }} md={{ "direction": "row" }}>
            <Col xs={12} md={7}>
              <Stack xs={{ "direction": "column", "spacing": "24", "justify": "center", "align": "center" }}>
                <Box
                  xs={{ "display": "none" }}
                  md={{ "display": "block" }}
                >
                  <Text
                    as="h1"
                    value={lp.title || "Landing Page Title"}
                    md={{ "color": "white", "variant": "h2", "align": "center" }}
                    lg={{ "variant": "h1" }}
                  />
                </Box>
                <Text
                  as="h3"
                  xs={{
                    "color": "white",
                    "variant": "h3",
                    "align": "center"
                  }}
                  value={lp.subtitle || "Landing Page Description"}
                />
                <Row>
                  {content.companies.map((company, index) => (
                    <Col
                      key={`company-${index}`}
                      xs={4}
                      sm={2}
                    >
                      <Center>
                        <img
                          src={company.logo?.publicURL}
                          alt={company.name}
                          style={{ height: 22 }}
                        />
                      </Center>
                    </Col>
                  ))}
                </Row>
                <MaybeLottie />
              </Stack>
            </Col>
            <Col xs={12} md={5}>
              <FormComponent content={form} />
            </Col>
          </Row>
        </Space>
      </Container>
    </Wrapper>
  )
})