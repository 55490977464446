import React from "react"
import { Text, Container, Space, Box, Stack, Row, Col, Center } from "@x-apps-projetos/design-system"

export const Brands = React.memo(({ content }) => {
  return (
    <Box
      id="section-brands"
      xs={{
        "width": "100%",
        "background": "primary-dark"
      }}
    >
      <Container maxWidth={1184}>
        <Space xs={{ "py": "32", "expand": true }}>
          <Row>
            <Col xs={12} md={7}>
              <Space xs={{ "px": "32", "pt": "64", "expand": true, "mb": "32" }}>
                <Stack
                  xs={{ "direction": "column", "spacing": "24", "align": "center" }}
                  md={{ "align": "flex-start" }}
                >
                  <Text
                    as="h2"
                    value={content.title}
                    xs={{ "color": "white", "variant": "h3", "align": "center" }}
                    md={{ "align": "start" }}
                  />
                </Stack>
              </Space>
              <Box xs={{
                "width": "100%",
                "borderWidth": 1,
                "borderStyle": "solid",
                "borderColor": "primary",
                "radius": "medium",
                "padding": "32"
              }}>
                <div style={{
                  "display": "flex",
                  "flexDirection": "row",
                  "flexWrap": "wrap",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "gap": "32px"
                }}>
                  {content.brands.map((brand, index) => (
                    <img
                      alt={brand?.name}
                      src={brand?.logo?.publicURL}
                      style={{ "alignSelf": "center", "justifySelf": "center" }}
                    />
                  ))}
                </div>
              </Box>
            </Col>
          </Row>
        </Space>
      </Container>
    </Box>
  )
})
