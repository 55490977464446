import React from "react"
import { FormInnerController } from "./controller"
import { FormInnerView } from "./view"

export const FormInner = React.memo(({ isEbookForm, content }) => {
  return (
    <FormInnerController isEbookForm={isEbookForm} content={content}>
      <FormInnerView isEbookForm={isEbookForm} content={content} />
    </FormInnerController>
  )
})