import React from "react"
import { LandingPageContext } from "./context"

export const LandingPageController = (props) => {

  const scrollToForm = () => {
    const element = document.getElementById("section-header")
    if (element) element.scrollIntoView({ "behavior": "smooth" })
  }

  const state = {
    scrollToForm
  }

  return (
    <LandingPageContext.Provider value={state}>
      {props.children}
    </LandingPageContext.Provider>
  )
}