import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #002043;

  & .slick-list{
    position: relative;
    height: 516px;
  }

`
