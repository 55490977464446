import React from "react"
import { LandingPageController } from "./controller"
import { LandingPageView } from "./view"

export const LandingPage = (props) => {
  return (
    <LandingPageController>
      <LandingPageView {...props} />
    </LandingPageController>
  )
}
