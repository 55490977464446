import React from "react"
import { useOnExitIntent } from "@utils/hooks/useOnExitIntent"
import { LandingPageModal } from "@components/_v2/landing-page-modal"
import { Wrapper } from "./style"
import { manageCookies } from "@utils/manageCookies"
import { Header } from "./components/header"
import { Hero } from "./components/hero"
import { Methods } from "./components/methods"
import { TestimonialsSection } from "./components/testimonials"
import { Cases } from "./components/cases"
import { Brands } from "./components/brands"
import { Faq } from "./components/faq"
import { Gartner } from "./components/gartner"
import { Footer } from "./components/footer"
import { Box, useModal } from "@x-apps-projetos/design-system"

export const LandingPageView = ({
  children,
  lp,
  mautic_form_id,
  mautic_form_ebook_id,
  section_form,
  section_header,
  section_hero,
  section_methods,
  section_cases,
  section_brands,
  section_faq,
  section_gartner,
  section_footer
}) => {

  const { open, close } = useModal()

  const showIntentModal = () => {
    open(
      <LandingPageModal
        content={{ mautic_form_id, mautic_form_ebook_id, ...section_form }}
        closeModal={close}
      />
    )
  }

  useOnExitIntent(() => {
    manageCookies.setExpiresInSeconds(60);
    showIntentModal();
  })


  return (
    <Wrapper>

      {children}

      <Header content={section_header} />

      <Hero lp={lp} content={section_hero} form={{ mautic_form_id, mautic_form_ebook_id, ...section_form }} />

      <Methods content={section_methods} />

      <TestimonialsSection />

      <Cases content={section_cases} />

      <Box
        xs={{ "display": "none", "width": "100%" }}
        md={{ "display": "flex" }}
      >
        <Brands content={section_brands} />
      </Box>

      <Faq content={section_faq} />

      <Gartner content={section_gartner} />

      <Footer
        showModal={showIntentModal}
        content={section_footer}
      />

    </Wrapper>
  )
}
