import React from "react"
import { FormController } from "./controller"
import { FormView } from "./view"

export const FormComponent = React.memo(({ content }) => {
  return (
    <FormController content={content}>
      <FormView content={content} />
    </FormController>
  )
})