import React from "react"
import { FormInnerContext } from "./context"
import { Stack, Text, Form, InputText, InputPhone, Button, Center } from "@x-apps-projetos/design-system"

export const FormInnerView = ({ isEbookForm, content }) => {

  const {
    errors,
    formRef,
    isSubmitting,
    handleSubmitForm,
  } = React.useContext(FormInnerContext)

  return (
    <Form
      onSubmit={handleSubmitForm}
      style={{ "width": "100%" }}
      ref={formRef}
    >
      <Stack xs={{ "direction": "column", "spacing": "16" }}>
        <Center>
          <Text
            xs={{
              "variant": "h4",
              "align": "center",
              "color": isEbookForm ? "white" : "primary",
            }}
            value={isEbookForm ? content.form_ebook_title : content.form_title}
          />
        </Center>
        <Stack xs={{ "direction": "column", "spacing": "12", "align": "stretch" }}>
          <InputText
            name="firstName"
            mode="none"
            autoFocus={true}
            error={errors?.firstName}
            label={content.form_field_name_label}
            labelColor={isEbookForm ? "white" : "primary"}
            placeholder={content.form_field_name_placeholder}
          />
          <InputText
            name="lastName"
            mode="none"
            error={errors?.lastName}
            label={content.form_field_surname_label}
            labelColor={isEbookForm ? "white" : "primary"}
            placeholder={content.form_field_surname_placeholder}
          />
          <InputText
            name="email"
            mode="none"
            error={errors?.email}
            label={content.form_field_email_label}
            labelColor={isEbookForm ? "white" : "primary"}
            placeholder={content.form_field_email_placeholder}
          />
          <InputPhone
            mode="none"
            name="phone"
            error={errors?.phone}
            label={content.form_field_phone_label}
            labelColor={isEbookForm ? "white" : "primary"}
            placeholder={content.form_field_phone_placeholder}
          />
        </Stack>
        <Center>
          <Button
            type="submit"
            shape="pill"
            wrap={true}
            center={true}
            state={isSubmitting ? "loading" : "idle"}
            color="secondary-gradient"
            label={isEbookForm ? content.form_ebook_button_label : content.form_submit_button_label}
          />
        </Center>
      </Stack>
    </Form>
  )
}