import React from "react"
import { LogoContainer, WrapperShape, WrapperContainer } from "./style"
import { Space, Row, Col, Stack, Button, Text, Box } from "@x-apps-projetos/design-system"
import { LandingPageContext } from "@components/_v2/landing-page/context"

export const Gartner = React.memo(({ content }) => {

  const { scrollToForm } = React.useContext(LandingPageContext)

  return (
    <Box
      id="section-gartner"
      xs={{
        "display": "flex",
        "background": "primary-dark",
        "width": "100%",
        "position": "relative",
        "zIndex": 1,
      }}
      md={{
        "background": "primary"
      }}
    >
      <WrapperShape
        xs={{ "display": "none" }}
        md={{ "display": "flex" }}
      />
      <WrapperContainer maxWidth={1184}>
        <Space
          xs={{ "pb": "64", "pt": "zero", "expand": true }}
          md={{ "pt": "64" }}
        >
          <Stack
            xs={{
              "direction": "column",
              "spacing": "32"
            }}
          >
            <Box
              xs={{
                "background": "primary",
                "padding": "42",
                "radius": "medium"
              }}
              md={{
                "background": "transparent",
                "padding": "zero"
              }}
            >
              <Row>
                <Col xs={12} md={4}>
                  <Stack
                    xs={{ "direction": "column", "align": "center", "spacing": "24" }}
                    md={{ "align": "flex-start" }}
                  >
                    <Text
                      as="h2"
                      value={content.title}
                      xs={{ variant: "h4", "color": "white", "align": "center" }}
                      md={{ variant: "h2", "align": "start" }}
                    />
                  </Stack>
                </Col>
                <Col xs={12} md={3}>
                  <LogoContainer md={{ "paddingTop": "92px" }}>
                    <img src={content?.logo?.publicURL} />
                  </LogoContainer>
                </Col>
              </Row>
            </Box>
            <Box
              xs={{ "display": "flex", "width": "100%" }}
              md={{ "display": "none" }}
            >
              <Button
                onClick={scrollToForm}
                color="secondary-gradient"
                size="large"
                zoom
                label={content.button_label}
              />
            </Box>
          </Stack>
        </Space>
      </WrapperContainer>
    </Box>
  )
})