import React from "react"
import { Space, Row, Col, Box, Button, Center } from "@x-apps-projetos/design-system"
import { Wrapper } from "./style"
import { LandingPageContext } from "@components/_v2/landing-page/context"
import { Testimonials } from "@components/_v2/_shared/testimonials"
export const TestimonialsSection = React.memo(() => {

  const { scrollToForm } = React.useContext(LandingPageContext)

  return (
    <Wrapper id="section-testimonials">
      <Space
        xs={{
          "pb": "zero",
          "pt": "64",
          "pr": "zero",
          "expand": true
        }}
        md={{ "pr": "64", "pb": "32" }}
        lg={{ "pr": "42" }}
        xl={{ "pr": "96" }}
        xxl={{ "pr": "160" }}
      >
        <Box
          xs={{ "display": "flex", "width": "100%", "marginTop": "-90px", "marginBottom": "60px", "zIndex": "999" }}
          md={{ "display": "none" }}
        >
          <Center>
            <Button
              color="secondary-gradient"
              size="large"
              zoom
              label="Quero falar com a X-Apps"
              wrap
              onClick={scrollToForm}
            />
          </Center>
        </Box>
        <Row>
          <Col xs={12} md={7}>
            <Testimonials shadowColor="#002043" />
          </Col>
        </Row>
      </Space>
    </Wrapper>
  )
})