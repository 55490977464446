import React from "react"
import Logo from "@components/Logo"
import { Box, Container, Space, Row, Col, Stack, Button, Text } from "@x-apps-projetos/design-system"
export const Footer = React.memo(({ showModal, content }) => {
  return (
    <Box
      id="section-footer"
      xs={{
        "display": "flex",
        "width": "100%",
        "background": "primary"
      }}
      md={{
        "background": "primary-dark"
      }}
    >
      <Container maxWidth={1184}>
        <Space xs={{ "py": "32" }}>
          <Row
            xs={{ "direction": "column-reverse", "spacing": { "vertical": "42" } }}
            sm={{ "direction": "row" }}
          >
            <Col xs={12} sm={6} md={3}>
              <Stack xs={{ "spacing": "16", "direction": "column", "align": "flex-start" }}>
                <Logo
                  sx={{
                    width: "100%",
                    svg: {
                      width: ["109px", "109px", "122px"],
                      margin: "0 auto",
                    },
                  }}
                />
                <Text
                  value={content.description}
                  xs={{ variant: "body_1", color: "white" }}
                />
              </Stack>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Stack
                xs={{ "spacing": "16", "direction": "column", "align": "flex-start" }}
              >
                <Text
                  value={content.doubts.title}
                  xs={{ variant: "h4", color: "white" }}
                />
                <Text
                  value={content.doubts.description}
                  xs={{ variant: "body_1", color: "white" }}
                />
                <Button
                  onClick={showModal}
                  color="primary-light"
                  size="tiny"
                  wrap
                  label={content.doubts.download_button_label}
                />
              </Stack>
            </Col>
          </Row>
        </Space>
      </Container>
    </Box>
  )
})