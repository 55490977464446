import styled from "@emotion/styled"

export const TextModalDescription = styled.div`
  color: white;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  & ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-top: 24px;
    & li {
      font-size: 14px;
    } 
  }
`