import React from "react"
import { Wrapper } from "./style"
import Logo from "@components/Logo"
import { Container, Space, Stack, Button, Text, Box } from "@x-apps-projetos/design-system"

export const Header = React.memo(({ content }) => {

  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId)
    if (element) element.scrollIntoView({ "behavior": "smooth" })
  }

  return (
    <Wrapper id="section-header">
      <Container maxWidth={1184}>
        <Space xs={{ "expand": true, "py": "24" }}>
          <Stack xs={{
            "spacing": "32",
            "direction": "row",
            "align": "center",
            "justify": "space-between"
          }}>
            <Logo
              sx={{
                width: "100%",
                svg: {
                  height: 42,
                  margin: "0 auto",
                },
              }}
            />
            <Box
              xs={{ "display": "none" }}
              md={{ "display": "flex" }}
            >
              <Stack xs={{
                "align": "center",
                "direction": "row",
                "justify": "flex-end",
                "spacing": "8"
              }}>
                {content.links.map((link, index) => (
                  <Button
                    key={`link-header-${index}`}
                    shape="pill"
                    size="tiny"
                    fill={link.evidence ? "outline" : "none"}
                    color={link.evidence ? "secondary" : "white"}
                    wrap={true}
                    paddingHorizontal={24}
                    onClick={() => scrollTo(link?.section)}
                  >
                    <Text
                      xs={{ "color": "white", "variant": "body_2" }}
                      value={link.label}
                    />
                  </Button>
                ))}
              </Stack>
            </Box>
          </Stack>
        </Space>
      </Container>
    </Wrapper>
  )
})