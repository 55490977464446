import React from "react"
import { FormWrapper, FormRow, FormColumn } from "./style"
import { Box, Container } from "@x-apps-projetos/design-system"
import { FormInner } from "../form-inner"

export const FormView = React.memo(({ content }) => {
  return (
    <FormWrapper
      id="section-form"
      xs={{
        "width": "100%",
        "display": "flex",
        "paddingBottom": "32px",
        "marginTop": "42px"
      }}
      md={{
        "position": "fixed",
        "top": "164px",
        "left": "0px",
        "right": "0px",
        "height": "0px",
        "paddingBottom": "0px",
        "marginTop": "0px"
      }}
    >
      <Container
        maxWidth={1184}
        xs={{ "paddingLeft": "zero", "paddingRight": "zero" }}
        md={{ "height": "0px", "paddingRight": "32" }}
        lg={{ "paddingLeft": "128", "paddingRight": "64" }}
      >
        <FormRow
          xs={{ "spacing": { "horizontal": "96" } }}
          md={{ "height": "0px" }}
        >
          <FormColumn
            xs={{ "span": 12, "height": "auto" }}
            md={{ "span": 7, "height": "0px" }}
          >
            &nbsp;
          </FormColumn>
          <FormColumn
            xs={{ "span": 12, "height": "auto" }}
            md={{ "span": 5, "height": "0px" }}
          >
            <Box
              xs={{
                "width": "100%",
                "padding": "32",
                "radius": "medium",
                "shadow": "dropped",
                "background": "white"
              }}
            >
              <FormInner content={content} />
            </Box>
          </FormColumn>
        </FormRow>
      </Container>
    </FormWrapper>
  )
})