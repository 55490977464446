import React from "react"
import { Wrapper } from "./style"
import { Text, Container, Space, Box, Stack, Row, Col, Accordion, Button } from "@x-apps-projetos/design-system"
import { LandingPageContext } from "@components/_v2/landing-page/context"

export const Faq = React.memo(({ content }) => {

  const { scrollToForm } = React.useContext(LandingPageContext)

  const questions = content.doubts.map((node, index) => ({
    title: (props) => (
      <Text
        value={`${index + 1}. ${node.question}`}
        xs={{
          "color": props.expanded ? "link" : "white",
          "variant": "h6"
        }}
      />
    ),
    content: (props) => (
      <Stack xs={{ "direction": "column", "spacing": "12", "align": "flex-start" }}>
        <Text
          xs={{ "color": "white", "variant": "body_1" }}
          value={node.answer}
        />
        <Box
          xs={{ "display": "block" }}
          md={{ "display": "none" }}
        >
          <Button
            color="secondary-gradient"
            size="small"
            label={content.button_contact_label}
            onClick={scrollToForm}
            wrap
          />
        </Box>
      </Stack>
    )
  }))

  return (
    <Wrapper id="section-faq">
      <Container maxWidth={1184}>
        <Space xs={{ "pb": "64", "pt": "32", "expand": true }}>
          <Row>
            <Col xs={12} md={7}>
              <Box
                xs={{ "width": "100%", "radius": "medium" }}
                md={{ "background": "primary" }}
              >
                <Space
                  xs={{ "expand": true }}
                  md={{ "px": "32", "py": "64" }}
                >
                  <Stack
                    xs={{ "direction": "column", "spacing": "24", "align": "center" }}
                    md={{ "align": "flex-start" }}
                  >
                    <Text value={content.title} xs={{ "color": "white", "variant": "h3" }} />
                    <Space xs={{ "expand": true }}>
                      <Accordion
                        xs={{
                          "backgroundColor": "transparent",
                          "borderColor": "primary-light",
                          "padding": "16"
                        }}
                        items={questions}
                      />
                    </Space>
                  </Stack>
                </Space>
              </Box>
            </Col>
          </Row>
        </Space>
      </Container>
    </Wrapper>
  )
})
