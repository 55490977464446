import React from "react"
import { FormContext } from "./context"

export const FormController = (props) => {
  const state = {}
  return (
    <FormContext.Provider value={state}>
      {props.children}
    </FormContext.Provider>
  )
}