import styled from "@emotion/styled"
import backgroundShape from "./assets/background_shape.svg"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #002043;
  background-image: url(${backgroundShape});
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: auto 100%;
  z-index: 1;
`