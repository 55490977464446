import React from "react"
import Picture from "@images/exitIntent/landing-page-picture.svg"
import IconClose from "@images/svg/close.svg"
import { FormInner } from "@components/_v2/landing-page/components/form-inner"
import { TextModalDescription } from "./style"
import { Box, Button, Col, Row, Space, Stack } from "@x-apps-projetos/design-system"
import remark from "remark"
import remarkHTML from "remark-html"
const remarkInstance = remark().use(remarkHTML)

export const LandingPageModalView = ({ closeModal, content }) => {
  return (
    <Box
      xs={{
        "background": "primary",
        "width": "100%",
        "height": "auto",
        "shadow": "dropped",
        "radius": "small",
        "padding": "32",
        "borderWidth": "2",
        "borderColor": "muted",
        "borderStyle": "solid"
      }}
      md={{
        "width": "800px",
      }}
    >
      <Stack xs={{ "direction": "column", "align": "flex-end", "spacing": "12" }}>
        <Button
          color="transparent"
          shape="pill"
          symetrycSize={32}
          onClick={closeModal}
        >
          <IconClose />
        </Button>
        <Space xs={{ "expand": true, "pl": "32", "pr": "32", "pb": "32" }}>
          <Row xs={{ "spacing": { "horizontal": "64" } }}>
            <Col xs={12} sm={6}>
              <Stack
                xs={{ "direction": "column", "spacing": "24" }}
              >
                <Picture
                  width="100%"
                  style={{ maxWidth: 164, maxHeight: 164 }}
                />
                <Stack xs={{ "direction": "column", "spacing": "24" }}>
                  <TextModalDescription
                    dangerouslySetInnerHTML={{
                      __html: remarkInstance.processSync(content.form_ebook_description).toString()
                    }}
                  />
                </Stack>
              </Stack>
            </Col>
            <Col xs={12} sm={6}>
              <FormInner content={content} isEbookForm={true} />
            </Col>
          </Row>
        </Space>
      </Stack>
    </Box>
  )
}