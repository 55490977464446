import styled from "@emotion/styled"
import { Box, Container } from "@x-apps-projetos/design-system"
import backgroundShape from "./assets/background_shape.svg"

export const WrapperShape = styled(Box)`
  position: absolute;
  left: calc(50% - 200px);
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 0;
  opacity: 0.3;
  background-image: url(${backgroundShape});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto;
`

export const WrapperContainer = styled(Container)`
  z-index:1;
`

export const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`