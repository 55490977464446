import React from "react"
import { navigate } from "gatsby"
import routes from "@utils/routes"
import { validate } from "@utils/validator"
import { FormInnerContext } from "./context"
import { sendDataToMautic } from "@utils/mautic"
import { goToThanksPage } from "@utils/goToThanksPage"
import { sendDataToLeadsCenter } from "@utils/leadsCenter"
import { useCampaignCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"

export const FormInnerController = ({ children, isEbookForm, content }) => {

  const MAUTIC_FORM_ID =
    process.env.GATSBY_STG_MAUTIC_FORM_ID ||
    (isEbookForm ? content?.mautic_form_ebook_id : content?.mautic_form_id)

  const formRef = React.useRef()
  const campaignData = useCampaignCookies()
  const [errors, setErrors] = React.useState({})
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleValidateData = async values => {
    const errors = await validate(values, {
      "phone": {
        "required": "Obrigatório",
        "e164": "Número de telefone inválido"
      },
      "firstName": {
        "required": "Obrigatório"
      },
      "lastName": {
        "required": "Obrigatório"
      },
      "email": {
        "required": "Obrigatório",
        "email": "Email inválido"
      }
    })
    return errors
  }

  // handle custom submit form
  const handleSubmitForm = async () => {
    if (!!isSubmitting) return
    try {
      const formData = formRef.current.getData();
      const errors = await handleValidateData(formData)
      if (errors !== true) return setErrors(errors)
      setErrors({})
      setSubmitting(true)
      const isEbookLp = content?.slug === "/e-book-desenvolvimento-software-aplicativo"
      const firstPage = sessionStorage.getItem("aquisition_page")

      const data = {
        ...formData,
        formId: MAUTIC_FORM_ID,
        phone: formData.phone.replace(/[^0-9\.]+/g, ""),
      }

      try {
        await sendDataToMautic(MAUTIC_FORM_ID, data, campaignData, firstPage)
        await sendDataToLeadsCenter(data, campaignData)
      } finally {
        if (isEbookLp) return navigate(routes.home)
        goToThanksPage(data)
      }
    } catch (error) {
      console.log(JSON.stringify(error))
      setSubmitting(false)
    }
  }

  const state = {
    formRef,
    isSubmitting,
    errors,
    handleSubmitForm
  }

  return (
    <FormInnerContext.Provider value={state}>
      {children}
    </FormInnerContext.Provider>
  )
}