import React from "react"
import { LandingPageModalController } from "./controller"
import { LandingPageModalView } from "./view"

export const LandingPageModal = ({ closeModal, content }) => {
  return (
    <LandingPageModalController>
      <LandingPageModalView closeModal={closeModal} content={content} />
    </LandingPageModalController>
  )
}