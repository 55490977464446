import React from "react"
import { Wrapper, WrapperContent } from "./style"
import { Col, Container, Row, Space, Stack, Text, Box } from "@x-apps-projetos/design-system"

export const Methods = React.memo(({ content }) => {
  return (
    <Wrapper id="section-methods">
      <WrapperContent>
        <Container maxWidth={1184}>
          <Space xs={{ "pb": "64", "pt": "64", "expand": true }}>
            <Row>
              <Col xs={12} md={7}>
                <Stack xs={{ "direction": "column", "spacing": "32" }}>
                  <Space
                    xs={{ "mb": "24", "expand": true }}
                    md={{ "mb": "zero", "ml": "48" }}
                  >
                    <Stack
                      xs={{ "justify": "center" }}
                      md={{ "justify": "flex-start" }}
                    >
                      <Text
                        as="h3"
                        xs={{ "variant": "h3", "color": "white", "align": "center" }}
                        md={{ "align": "start" }}
                        value={content.title}
                      />
                    </Stack>
                  </Space>
                  {content.methods.map((method, index) => (
                    <Box
                      xs={{
                        "padding": "24",
                        "background": "primary-dark",
                        "radius": "medium"
                      }}
                      md={{
                        "marginLeft": "48px"
                      }}
                      key={`method-${index}`}
                    >
                      <Stack
                        xs={{ "direction": "column", "align": "center", "spacing": "24" }}
                        md={{ "direction": "row" }}
                      >
                        <Box
                          xs={{
                            "background": "primary-light",
                            "width": "48px",
                            "height": "48px",
                            "display": "flex",
                            "padding": "12",
                            "radius": "medium",
                            "marginTop": "-32px"
                          }}
                          md={{
                            "padding": "16",
                            "width": "98px",
                            "height": "98px",
                            "marginTop": "0px",
                            "marginLeft": "-48px"
                          }}
                        >
                          <img src={method.icon?.publicURL} />
                        </Box>
                        <Stack
                          xs={{ "direction": "column", "spacing": "8", "justify": "center", "align": "center" }}
                          md={{ "justify": "flex-start", "align": "flex-start" }}
                        >
                          <Text
                            xs={{ "variant": "h4", "color": "white", "align": "center" }}
                            md={{ "variant": "h6", "align": "start" }}
                            value={method.title}
                          />
                          <Text
                            xs={{ "variant": "body_2", "color": "white", "align": "center" }}
                            md={{ "align": "start" }}
                            value={method.description}
                          />
                        </Stack>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              </Col>
            </Row>
          </Space>
        </Container>
      </WrapperContent>
    </Wrapper>
  )
})